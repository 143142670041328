<template>
  <div>
    <div class="title-panel">
      <b-row>
        <b-col md="6" class="text-left">Theme Setting</b-col>
      </b-row>
    </div>
    <b-container class="no-gutters bg-white p-2 p-md-3">
      <div>
        <ColorPicker
          textFloat="Header Font Color"
          name="header_color"
          class="mb-2"
          v-model="form.theme_primary_color"
          :value="form.theme_primary_color"
        />
        <ColorPicker
          textFloat="Font Color"
          name="font_color"
          class="mb-2"
          v-model="form.theme_secondary_color"
          :value="form.theme_secondary_color"
        />
        <ColorPicker
          textFloat="Icon Color"
          name="icon_color"
          class="mb-2"
          v-model="form.theme_icon_color"
          :value="form.theme_icon_color"
        />
        <div>
          <b-form-group label="Background">
            <b-form-radio-group
              id="radio-slots"
              v-model="form.theme_background_page"
              stacked
              name="radio-options-slots"
            >
              <b-form-radio name="select-background-box" value="1"
                >Solid Color</b-form-radio
              >
              <b-form-radio name="select-background-box" value="2"
                >Image <small></small
              ></b-form-radio>
            </b-form-radio-group>
          </b-form-group>

          <ColorPicker
            v-if="form.theme_background_page == 1"
            name="font_color"
            v-model="form.theme_background"
            :value="form.theme_background"
          />

          <UploadImageAndPreview2
            v-if="form.theme_background_page == 2"
            v-model="form.theme_background"
            :value="form.theme_background"
            className="image-upload-preview-box"
            @deleteImage="deleteImage"
            name="themeBackGround"
            desc="Dimension 345x159 px not over 1 MB (Please attach file .jpg only)"
          />
        </div>
      </div>
    </b-container>
  </div>
</template>
<script>
import ColorPicker from "@/components/inputs/InputColorPicker";

import UploadImageAndPreview2 from "@/components/inputs/UploadImageAndPreview2";
export default {
  components: { ColorPicker, UploadImageAndPreview2 },
  props: {
    form: { require: true },
  },
  methods: {
    deleteImage() {
      this.form.theme_background = "";
    },
  },
};
</script>
<style lang="scss">
.image-upload-preview-box {
  height: 159px;
  width: 345px;
  display: flex;
  justify-content: center;
  position: relative;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
}
</style>
