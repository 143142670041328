<template>
  <div>
    <div v-show="isLoading">
      <OtherLoading />
    </div>
    <div v-show="!isLoading">
      <b-row class="no-gutters mb-3">
        <b-col>
          <div class="font-weight-bold ft-20 header-tablepage">
            Redeem Detail
          </div>
        </b-col>
      </b-row>
      <div>
        <b-tabs>
          <b-tab title="Information" @click="tabCurrent = 1">
            <RedeemForm
              :id="id"
              :form="form"
              :v="$v.form"
              v-on:openProductModal="openProductModal"
              ref="RedeemDetail"
            />
          </b-tab>
          <b-tab title="Report" @click="tabCurrent = 2" v-if="id != 0">
            <RedeemReport
              :id="id"
              :data="info"
              ref="RedeemOrderList"
              v-on:confirmAction="confirmAction"
            />
          </b-tab>
          <b-tab title="Theme">
            <RedeemTheme :form="form" />
          </b-tab>
        </b-tabs>
      </div>

      <FooterAction @submit="checkForm" routePath="/redeem" />

      <ProductModal
        :form="form"
        @openProductModal="openProductModal"
        v-on:submitProduct="submitProduct"
        ref="ProductListModal"
      />
    </div>
    <ModalConfirm
      ref="modalConfirm"
      :text="confirmText"
      @handler="confirmHideModal"
    />
  </div>
</template>

<script>
import RedeemForm from "@/components/redeem/detail/RedeemForm";
import RedeemReport from "@/components/redeem/report/RedeemReport.vue";
import RedeemTheme from "@/components/redeem/detail/RedeemTheme.vue";
import OtherLoading from "@/components/loading/OtherLoading";
import { required, requiredIf, minValue } from "vuelidate/lib/validators";
import ProductModal from "@/components/redeem/modal/ProductModal.vue";
import ModalConfirm from "@/components/modal/ModalConfirm";
import * as moment from "moment/moment";
export default {
  name: "RedeemDetail",
  components: {
    OtherLoading,
    RedeemForm,
    RedeemReport,
    RedeemTheme,
    ProductModal,
    ModalConfirm,
  },
  data() {
    return {
      id: parseInt(this.$route.params.id),
      isLoading: false,
      isDisable: false,
      tabCurrent: 1,
      form: {
        RedeemOrderId: this.id,
        Name: "",
        totalRedeemStatus: false,
        Total_Redeemable: 0,
        Skip: 1,
        pick_Up_Type_Id: 1,
        Status: 0,
        redeemType: 1,
        Valid_From: "",
        Valid_To: "",
        items: {
          selectProduct: [],
          DeleteProduct: [],
        },
        theme_primary_color: "#9929bd",
        theme_secondary_color: "#9929bd",
        theme_icon_color: "#9929bd",
        theme_background: "#9929bd",
        theme_background_page: 1,
      },
      info: {
        totalRedeem: 19954,
        received: 12780,
        waitForReceived: 9146,
        cancel: 258,
      },
      filter: {
        redeemId: parseInt(this.$route.params.id),
        page: 1,
        length: 10,
        start: 0,
        search: "",
        branchId: 0,
        redeemOrderStatus: 0,
      },
      actionStatus: "",
      confirmText: "",
      submitType: 0,
      rows: 0,
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
    };
  },
  validations: {
    form: {
      Name: { required },
      Total_Redeemable: {
        required: requiredIf(function () {
          return this.form.totalRedeemStatus;
        }),
        minValue: function () {
          if (
            this.form.totalRedeemStatus &&
            parseInt(this.form.Total_Redeemable) <= 0
          ) {
            return false;
          } else {
            return true;
          }
        },
      },
      Valid_From: { required },
      Valid_To: { required },
      items: {
        selectProduct: {
          required: requiredIf(function () {
            return this.form.items.selectProduct.length > 0;
          }),
          $each: {
            point_used: { required, minValue: minValue(1) },
          },
        },
      },
    },
  },
  created() {
    document.body.style.setProperty("--margin-bottom", "4rem");
    this.getRedeemOrderList();
  },
  destroyed() {
    document.body.style.setProperty("--margin-bottom", "0px");
  },
  methods: {
    async getRedeemOrderList() {
      this.isLoading = true;
      this.isBusy = true;

      await this.$store.dispatch("getRedeemDetail", this.filter);
      const data =
        this.$store.state.redeem.redeemDetail.detail.data.redeemDetail;
      const result = this.$store.state.redeem.redeemDetail.result;
      if (result == 1) {
        this.form = {
          RedeemOrderId: this.id,
          Name: data.redeem_Name,
          totalRedeemStatus: data.total_Redeemable > 0 ? true : false,
          Total_Redeemable: data.total_Redeemable,
          Skip: data.redeem_Skip,
          Status: data.status,
          pick_Up_Type_Id:
            data.pick_Up_Type_Id === 0 ? 1 : data.pick_Up_Type_Id,
          redeemType: 1,
          Valid_From: data.valid_From
            ? moment(data.valid_From).format()
            : moment().format(),
          Valid_To: data.valid_To
            ? moment(data.valid_To).format()
            : moment().add("day", 1).format(),
          items: {
            selectProduct: [],
            DeleteProduct: [],
          },
          theme_primary_color: data.theme_primary_color,
          theme_secondary_color: data.theme_secondary_color,
          theme_icon_color: data.theme_icon_color,
          theme_background: data.theme_background,
          theme_background_page: data.theme_background_page,
        };
        this.info = {
          totalRedeem: data.count_Redeemed,
          received: data.received,
          waitForReceived: data.wait_For_Receive,
          cancel: data.canceled,
        };
        this.$refs.RedeemDetail.filter.selectProductList =
          this.form.items.selectProduct;
        this.$refs.RedeemDetail.getProductSelectList(0);
        if (this.id != 0) {
          this.$refs.RedeemOrderList.getRedeemOrderList();
          // this.$refs.RedeemOrderList.getBranchList();
        }
      }
      this.isLoading = false;
      this.isBusy = false;
    },
    async checkForm(flag) {
      this.form.Status = this.form.Status ? 1 : 0;
      // await this.$refs.RedeemDetail.setProductPoint();
      // this.$v.form.$touch();
      // if (this.$v.form.$error) {
      //   return;
      // }

      this.submitForm();
    },
    async submitForm() {
      try {
        this.$EventBus.$emit("showLoading");
        await this.$store.dispatch("createOrUpdateRedeem", this.form);
        const data = this.$store.state.redeem.createOrUpdate;

        if (data.result == 1) {
          this.successAlert().then(
            (value) => value && this.$router.push("/redeem")
          );
        } else {
          this.errorAlert(data.message);
        }
        this.$EventBus.$emit("hideLoading");
      } catch (error) {
        this.$EventBus.$emit("hideLoading");
        console.log(error);
      }
    },
    confirmAction() {
      this.confirmHideModal();
    },
    confirmHideModal(e) {
      if (this.actionStatus == "save") {
        this.submitForm();
      } else if (this.actionStatus == "delete") {
        this.deleteData();
      } else {
        this.$refs.RedeemOrderList.changeStatus();
      }
    },
    async openProductModal(type) {
      console.log(this.form.items.selectProduct);
      await this.$refs.ProductListModal.show(
        type,
        this.form.items.selectProduct.map((e) => {
          return e.id;
        })
      );
    },
    submitProduct(select) {
      let filterSelect = [];
      console.log(select);
      this.form.items.selectProduct = select;
      select.forEach((e) => {
        this.$refs.RedeemDetail.filter.DeleteProductList =
          this.$refs.RedeemDetail.filter.DeleteProductList.filter(
            (id) => id != e.id
          );
        this.$refs.RedeemDetail.filter.selectProductList.forEach((filter) => {
          if (filter.id == e.id) {
            e.point_used = filter.point_used;
            e.is_theme = filter.is_theme;
            e.redeem_type_id = filter.redeem_type_id;
          }
          filterSelect.push(e);
        });
      });
      console.log([this.$refs.RedeemDetail.filter.selectProductList]);
      console.log(filterSelect);
      this.$refs.RedeemDetail.filter.selectProductList = select;

      this.$refs.RedeemDetail.filter.start = 0;
      this.$refs.RedeemDetail.filter.page = 1;
      this.$refs.RedeemDetail.getProductSelectList(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.ft-20 {
  font-size: 20px;
}
::v-deep .nav-tabs .nav-link:not(.active):hover {
  background-color: transparent;
  color: var(--primary-color);
  border-bottom: 4px solid var(--primary-color);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border-bottom: 4px solid var(--primary-color) !important;
}
.footer {
  z-index: 2;
  position: fixed;
  bottom: 0;
  left: 256px;
  right: 0;
  padding: 10px;
  background: #fff;
  z-index: 999;
}
.btn-template {
  color: var(--primary-color);
  border-color: var(--primary-color);
  background-color: transparent;
  border-radius: 0px;
  width: 30%;
}
.btn-confirm-automation {
  color: #fff;
  border-radius: 0px;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  width: 30%;
}
.btn-cancel-automation {
  color: #fff;
  background-color: #39424e;
  border-radius: 0px;
  width: 30%;
  border: 1px solid #39424e;
}
.btn-delete-automation {
  background-color: transparent;
  border: 1px solid #39424e;
  border-radius: 0px;
  width: 30%;
  color: #39424e;
}
::v-deep .nav-tabs .nav-link {
  width: 150px;
  text-align: center;
}
</style>
